import api from './request';

// 查询角色列表
export const getRoleList = (data: { projectId?: number; roleName?: string; page?: Basic.Page }) =>
  api.post<Role.ListData>('/auth-admin/role/query', data);

// 获取详情
export const getRoleDetail = (data: { projectId: number; roleId: number }) =>
  api.post<{
    role: Role.RoleItem;
    resource: Resource.BindResourceItem[];
  }>('/auth-admin/role/getRoleInfo', data);

// 新建/更新角色信息
export const createOrUpdateRole = (data: Role.RoleItem) =>
  api.post('/auth-admin/role/createOrUpdate', data);

// 删除角色
export const deleteRole = (data: { projectId: number; roleId: number }) =>
  api.post('/auth-admin/role/delete', data);

// 绑定资源
export const bingRoleResources = (data: {
  resources: Role.TreeItem[];
  roleId: number;
  projectId: number;
}) => api.post('/auth-admin/role/bingRoleResources', data);

// 获取项目下所有角色
export const getProjectAllRole = (projectId: number) =>
  api.post<{ roleList: Role.RoleItem[] }>('/auth-admin/role/getProjectAllRole', { projectId });
